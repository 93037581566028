import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Card, CardBody, CardHeader, Row, Col, Button } from 'reactstrap';
import { PanelHeader, Spinner, AlertConfirm } from '../../../components';
import {
  loadPlan,
  savePlan,
  one,
  removePlanTask,
  removePlanNote,
  removePlanImage
} from '../../../helpers/actions/projects';
/* import NavbarProject from '../NavbarActions/NavbarProject'; */
import PlanKonva from '../../../components/Canvas';
import { getUrlParam } from '../../../helpers/urlParams';
import NoteModal from './Elements/NoteModal';
import TaskModal from './Elements/TaskModal';
import ImageModal from './Elements/ImageModal';
import ImageDetails from './Elements/ImageDetails';
import { isMobile } from 'react-device-detect';
import ElementsTabs from './ElementsTabs';
import { planIcons, planCustomElements } from './Elements/config';

class PlanDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      plan: null,
      page: 0,
      project: null,
      note: null,
      task: null,
      image: null,
      newElements: [],
      hTabs: 'tasks'
    };
  }

  componentWillMount() {
    this.load();
  }

  load() {
    this.setState({ loading: true });

    let { id, planId } = this.props.match.params,
      { dispatch } = this.props;

    let promises = [
      dispatch(
        one(id, {
          include: ['none'],
          fields: ['type', 'name', 'status']
        })
      ),
      dispatch(loadPlan(id, planId))
    ];

    Promise.all(promises)
      .then(async ([project, plan]) => {
        let page = getUrlParam('page', 0);
        this.setState(ps => ({
          ...ps,
          project,
          plan,
          loading: false,
          page,
          elementDelete: null
        }));
      })
      .catch(() => this.setState(ps => ({ ...ps, loading: false })));
  }

  changePage(page) {
    let { plan } = this.state;
    if (page >= 0 && page < plan.pages.length) {
      this.setState(ps => ({ ...ps, page }));
    }
  }

  saveElements(newElements, updateState = {}) {
    this.setState(ps => ({ ...ps, loading: true }));
    let { plan, page } = this.state;
    let { dispatch } = this.props;
    // update only elements in the current page
    let filtered = plan.elements.filter(
      e => parseInt(e.page) !== parseInt(page)
    );
    let updates = {
      _id: plan._id,
      elements: filtered.concat(newElements)
    };

    dispatch(savePlan(plan._id, updates))
      .then(planUpdated => {
        this.setState(ps => ({
          ...ps,
          plan: planUpdated,
          ...updateState,
          loading: false
        }));
      })
      .catch(err => {});
  }

  selectCustomElement = data => {
    if (data._id) {
      switch (data.type) {
        case 'images':
          this.setState(ps => ({ ...ps, image: { _id: data._id } }));
          break;
        case 'notes':
          this.setState(ps => ({ ...ps, note: { _id: data._id } }));
          break;
        case 'tasks':
          this.setState(ps => ({ ...ps, task: { _id: data._id } }));
          break;
        default:
          break;
      }
    }
  };

  createCustomElement = data => {
    let newElement = data[data.length - 1];
    switch (newElement.type) {
      case 'images':
        this.setState(ps => ({
          ...ps,
          newElements: data,
          image: { type: 'other' }
        }));
        break;
      case 'notes':
        this.setState(ps => ({
          ...ps,
          newElements: data,
          note: { type: 'other' }
        }));
        break;
      case 'tasks':
        this.setState(ps => ({
          ...ps,
          newElements: data,
          task: { type: 'other' }
        }));
        break;
      default:
        break;
    }
  };

  deleteCustomElement = (data, elementsData = null) => {
    let { id, planId } = this.props.match.params,
      { dispatch } = this.props;

    if (data._id) {
      switch (data.type) {
        case 'images':
          dispatch(removePlanImage(id, planId, data._id))
            .then(() => {
              this.saveElements(elementsData);
            })
            .catch(err => {});
          break;
        case 'image':
          dispatch(removePlanImage(id, planId, data._id))
            .then(() => {
              this.load();
            })
            .catch(err => {});
          break;
        case 'notes':
          dispatch(removePlanNote(id, planId, data._id))
            .then(() => {
              this.saveElements(elementsData);
            })
            .catch(err => {});
          break;
        case 'note':
          dispatch(removePlanNote(id, planId, data._id))
            .then(() => {
              this.load();
            })
            .catch(err => {});
          break;
        case 'tasks':
          dispatch(removePlanTask(id, planId, data._id))
            .then(() => {
              this.saveElements(elementsData);
            })
            .catch(err => {});
          break;
        case 'task':
          dispatch(removePlanTask(id, planId, data._id))
            .then(() => {
              this.load();
            })
            .catch(err => {});
          break;
        default:
          break;
      }
    }
  };

  onConfirm = data => {
    let { newElements } = this.state;
    if (newElements.length) {
      newElements[newElements.length - 1]._id = data._id;
      switch (newElements[newElements.length - 1].type) {
        case 'notes':
          this.saveElements(newElements, {
            newElements: [],
            note: { _id: data._id }
          });
          break;
        case 'tasks':
          this.saveElements(newElements, {
            newElements: [],
            task: { _id: data._id }
          });
          break;
        case 'images':
          this.saveElements(newElements, {
            newElements: [],
            image: { _id: data._id, ...data }
          });
          break;
        default:
          break;
      }
    }
  };

  deleteElement(elementDelete) {
    this.setState(ps => ({ ...ps, elementDelete }));
  }

  selectElement(type, data) {
    this.setState(ps => ({ ...ps, [type]: data }));
  }

  onCancel = () => {
    this.setState(ps => ({
      ...ps,
      newElements: [],
      note: null,
      task: null,
      image: null
    }));
  };

  render() {
    let { loading, plan, page, note, task, image, elementDelete } = this.state;
    let elementsByPage = [];

    if (plan && plan.pages && plan.pages[page]) {
      elementsByPage = plan.elements.filter(
        ep => parseInt(ep.page) === parseInt(page)
      );
    }

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {/*   {project ? <NavbarProject project={project} /> : null} */}
          {plan ? (
            <Card className={'card-flex'}>
              {loading ? <Spinner inside={true} /> : null}
              <CardHeader>
                <Row>
                  <Col xs={10} md={8}>
                    <h6>{plan.name}</h6>
                    <div
                      dangerouslySetInnerHTML={{ __html: plan.description }}
                    />
                  </Col>
                  <Col xs={2} md={4} className={'text-right pl-0'}>
                    <Button
                      color={'info'}
                      size={'sm'}
                      className={'mt-0 mb-2'}
                      onClick={this.props.history.goBack}
                    >
                      <i className="fas fa-arrow-left" />
                    </Button>
                  </Col>
                </Row>
                <hr className={'m-0'} />
              </CardHeader>
              <CardBody className={'pt-2'}>
                <Row>
                  <Col xs={12} md={12} lg={8} className={'mb-4 p-1'}>
                    <Row className={'pl-2 pr-2'}>
                      <Col xs={6} md={8}>
                        <h5 className={'m-0'}>
                          Page {parseInt(page) + 1} /{' '}
                          {plan ? plan.pages.length : 0}
                        </h5>
                      </Col>
                      <Col xs={6} md={4} className={'text-right'}>
                        <Button
                          size={'sm'}
                          color="info"
                          disabled={
                            plan.pages.length === 1 || page === 0 ? true : false
                          }
                          className="text-right m-0 mr-2"
                          onClick={() => this.changePage(parseInt(page) - 1)}
                        >
                          {' '}
                          <i className="fa fa-chevron-left" />{' '}
                        </Button>
                        <Button
                          size={'sm'}
                          color="info"
                          disabled={
                            page.toString() ===
                            (plan.pages.length - 1).toString()
                              ? true
                              : false
                          }
                          className="text-right m-0"
                          onClick={() => this.changePage(parseInt(page) + 1)}
                        >
                          <i className="fa fa-chevron-right" />
                        </Button>
                      </Col>
                    </Row>
                    <hr className={'mb-0 mt-2'} />
                    <div className={'border-canvas'}>
                      {plan.pages && plan.pages[page] ? (
                        <PlanKonva
                          id={'plans-konva'}
                          isMobile={isMobile}
                          backgroundImage={plan.pages[page].Location}
                          elementDefaultData={{ page }}
                          elementsData={elementsByPage}
                          saveData={data => this.saveElements(data)}
                          customElements={planCustomElements}
                          icons={planIcons}
                          customElementSelect={this.selectCustomElement}
                          customElementCreate={this.createCustomElement}
                          customElementDelete={this.deleteCustomElement}
                          selectColor={true}
                          selectOpacity={true}
                          selectSize={true}
                        />
                      ) : null}
                    </div>
                  </Col>
                  <ElementsTabs
                    plan={plan}
                    deleteElement={data => this.deleteElement(data)}
                    selectElement={(type, data) =>
                      this.selectElement(type, data)
                    }
                  />
                </Row>

                {note ? (
                  <NoteModal
                    onConfirm={this.onConfirm}
                    onCancel={this.onCancel}
                    projectId={plan.project}
                    planId={plan._id}
                    note={note}
                  />
                ) : null}
                {task ? (
                  <TaskModal
                    onConfirm={this.onConfirm}
                    onCancel={this.onCancel}
                    projectId={plan.project}
                    planId={plan._id}
                    task={task}
                  />
                ) : null}
                {image && !image._id ? (
                  <ImageModal
                    onConfirm={this.onConfirm}
                    onCancel={this.onCancel}
                    projectId={plan.project}
                    planId={plan._id}
                    image={image}
                  />
                ) : null}
                {image && image._id ? (
                  <ImageDetails
                    onConfirm={this.onConfirm}
                    onCancel={this.onCancel}
                    projectId={plan.project}
                    planId={plan._id}
                    image={image}
                  />
                ) : null}
                {elementDelete ? (
                  <AlertConfirm
                    message={'The item cannot be recovered'}
                    onCancel={() => this.setState({ elementDelete: null })}
                    onConfirm={() => this.deleteCustomElement(elementDelete)}
                  />
                ) : null}
              </CardBody>
            </Card>
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(PlanDetails));
