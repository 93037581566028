import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  FormGroup,
  Input,
  Label,
  Col
} from 'reactstrap';
import { Spinner } from '../../../components';
import {
  one,
  sendPlanTaskByEmail,
  sendPlanNoteByEmail,
  sendPlanImageByEmail
} from '../../../helpers/actions/projects';

class SendPlanElementEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      projectId: null,
      itemId: null,
      itemType: '' // document, note, event
    };
  }

  load() {
    this.setState({ loading: true });
    let { dispatch, projectId, itemId, itemType } = this.props;

    dispatch(
      one(projectId, {
        include: ['client', 'pro', 'supervisors', 'sellers'],
        fields: ['client', 'pro', 'supervisors', 'sellers']
      })
    )
      .then(p => {
        this.setState(
          ps => ({
            ...ps,
            supervisors: p.supervisors ? p.supervisors : [],
            pro: p.pro && p.pro.email ? p.pro.email : null,
            cli: p.client && p.client.email ? p.client.email : null,
            sellers: p.sellers ? p.sellers : [],
            emails: [],
            sendEmails: [],
            email: '',
            itemId,
            itemType,
            loading: false
          }),
          () => {}
        );
      })
      .catch(() => this.setState(ps => ({ ...ps, loading: false })));
  }

  componentWillMount() {
    this.load();
  }

  validate() {
    let email = this.email;

    this.setState({
      [email.attributes.getNamedItem('namevalid').value]: email.validity.valid
        ? 'has-success'
        : 'has-danger'
    });

    return email.validity.valid;
  }

  addEmail() {
    if (this.validate()) {
      let { emails, email, sendEmails } = this.state;
      emails.push(email);
      sendEmails.push(email);
      this.setState(ps => {
        return { ...ps, emails, sendEmails };
      });
    }
  }

  addUserEmail(event, email) {
    if (event.target.checked)
      this.setState(ps => {
        ps.sendEmails.push(email);
        return { ...ps, sendEmails: ps.sendEmails };
      });
    else
      this.setState(ps => {
        ps.sendEmails.slice(ps.sendEmails.indexOf(email), 1);
        return { ...ps, sendEmails: ps.sendEmails };
      });
  }

  sendByEmail() {
    this.setState({ loading: true });
    let { itemId, itemType, emails, sendEmails } = this.state;
    let { projectId, planId, dispatch } = this.props;

    let listOfEmails = [...sendEmails, ...emails];

    switch (itemType) {
      case 'task':
        dispatch(
          sendPlanTaskByEmail(projectId, planId, itemId, {
            emails: listOfEmails
          })
        )
          .then(() => {
            this.setState({ loading: false, itemId: null }, () =>
              this.props.hide()
            );
          })
          .catch(() =>
            this.setState({ loading: false }, () => this.props.hide())
          );
        break;
      case 'image':
        dispatch(
          sendPlanImageByEmail(projectId, planId, itemId, {
            emails: listOfEmails
          })
        )
          .then(() => {
            this.setState({ loading: false, itemId: null }, () =>
              this.props.hide()
            );
          })
          .catch(() =>
            this.setState({ loading: false }, () => this.props.hide())
          );
        break;
      case 'note':
        dispatch(
          sendPlanNoteByEmail(projectId, planId, itemId, {
            emails: listOfEmails
          })
        )
          .then(() => {
            this.setState({ loading: false, itemId: null }, () =>
              this.props.hide()
            );
          })
          .catch(() =>
            this.setState({ loading: false }, () => this.props.hide())
          );
        break;
      default:
        break;
    }
  }

  cleanModal() {
    this.setState({
      emailValid: null
    });
  }

  closeModal() {
    this.cleanModal();
    this.props.onCancel();
  }

  render() {
    let { loading, itemId, itemType } = this.state;

    return itemId ? (
      <Modal key={itemType + itemId} size={'md'} isOpen={itemId !== null}>
        {loading ? <Spinner /> : null}
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.props.hide()}
        >
          <Trans>Select users</Trans>
        </ModalHeader>
        <ModalBody>
          <Row>
            {this.state.supervisors.map(s => {
              return (
                <Col key={s.email} xs={12} sm={12} md={12} lg={12}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value={s.email}
                        onChange={event => this.addUserEmail(event, s.email)}
                      />
                      <span className="form-check-sign" />
                      <Trans>Supervisor</Trans>
                      {' - '}
                      {s.email}
                    </Label>
                  </FormGroup>
                </Col>
              );
            })}
            {this.state.sellers.map(s => {
              return (
                <Col key={s.email} xs={12} sm={12} md={12} lg={12}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value={s.email}
                        onChange={event => this.addUserEmail(event, s.email)}
                      />
                      <span className="form-check-sign" />
                      <Trans>Seller</Trans>
                      {' - '}
                      {s.email}
                    </Label>
                  </FormGroup>
                </Col>
              );
            })}
            {this.state.pro ? (
              <Col xs={12} sm={12} md={12} lg={12}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={this.state.proCheck}
                      onChange={event =>
                        this.addUserEmail(event, this.state.pro)
                      }
                    />
                    <span className="form-check-sign" />
                    <Trans>Professional</Trans>
                    {' - '}
                    {this.state.pro}
                  </Label>
                </FormGroup>
              </Col>
            ) : null}
            {this.state.cli ? (
              <Col xs={12} sm={12} md={12} lg={12}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={this.state.cliCheck}
                      onChange={event =>
                        this.addUserEmail(event, this.state.cli)
                      }
                    />
                    <span className="form-check-sign" />
                    <Trans>Client</Trans>
                    {' - '}
                    {this.state.cli}
                  </Label>
                </FormGroup>
              </Col>
            ) : null}
            <Col xs={12} sm={12} md={12} lg={12}>
              <hr />
              <ul>
                {this.state.emails.map((email, index) => (
                  <li key={index}>{email}</li>
                ))}
              </ul>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8}>
              <FormGroup
                className={
                  'has-label form-validation-10 ' + this.state.emailValid
                }
              >
                <Input
                  type="email"
                  innerRef={node => (this.email = node)}
                  namevalid="emailValid"
                  placeholder="email@email.com"
                  value={this.state.email}
                  required={true}
                  onChange={event =>
                    this.setState({
                      email: event.target.value,
                      emailValid: event.target.validity.valid
                        ? 'has-success'
                        : 'has-danger'
                    })
                  }
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={4} md={4} lg={4} className={'text-right'}>
              <Button
                color={'info'}
                className={'m-0'}
                onClick={() => this.addEmail()}
              >
                <Trans>Add</Trans>
              </Button>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            className="text-left"
            onClick={() => this.props.hide()}
          >
            <Trans>Close</Trans>
          </Button>
          <Button
            color="info"
            className="text-right"
            onClick={() => this.sendByEmail()}
          >
            <Trans>Send</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    ) : null;
  }
}

export default connect(state => state)(
  translate('translations-fr')(SendPlanElementEmail)
);
