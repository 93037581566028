import { notify } from './index';
import get from 'lodash/get';

export const all = params => (dispatch, getState, { api }) =>
  api.Clients.all(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const one = id => (dispatch, getState, { api }) =>
  api.Clients.one(id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const save = client => (dispatch, getState, { api }) =>
  api.Clients.save(client)
    .then(response => {
      dispatch(notify('info', 'Client saved'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const remove = id => (dispatch, getState, { api }) =>
  api.Clients.del(id)
    .then(response => {
      dispatch(notify('info', 'Client removed'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const photo = (id, file) => (dispatch, getState, { api }) =>
  api.Clients.photo(id, file)
    .then(response => {
      dispatch(notify('info', 'Client photo saved'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const getDisplayName = uid => (dispatch, getState, { api }) =>
  api.Clients.one(uid)
    .then((user = {}) => ({
      photo: get(user, 'photoUrl'),
      displayName: get(user, 'displayName')
    }))
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
