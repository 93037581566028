import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import {
  loadPlanImages,
  savePlanImage
} from '../../../../helpers/actions/projects';
import GalleryModal from '../../../../components/Gallery/GalleryModal';
import _ from 'lodash';

class ImageDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      planImages: [],
      image: null,
      planId: null,
      projectId: null,
      loading: false
    };
  }

  load() {
    let { image, planId, projectId, dispatch } = this.props;
    if (image._id)
      dispatch(loadPlanImages(projectId, planId))
        .then(planImages => {
          let imageSelected = null;
          let images = _.cloneDeep(planImages).map(pi => {
            let media = {
              ...pi.media,
              id: pi.media._id,
              src: pi.media.source,
              /* pi.media.source.toString() +
                '?v=' +
                moment()
                  .unix()
                  .toString(), */
              isSelected: image._id === pi._id ? true : false
            };

            if (media.isSelected) imageSelected = media;

            return media;
          });

          this.setState(ps => ({
            ...ps,
            planImages,
            image: imageSelected,
            images,
            planId,
            projectId,
            loading: false
          }));
        })
        .catch(err => {});
  }

  componentWillMount() {
    this.load();
  }

  savePlanImage() {}

  cleanModal() {
    this.setState({
      image: {},
      plan: {},
      loading: false
    });
  }

  closeModal() {
    let { projectId, planId, images, planImages } = this.state;
    let { dispatch } = this.props;
    let promises = [];

    images.forEach((m, i) => {
      delete m.ref;
      if (!_.isEqual(m.points, planImages[i].media.points)) {
        let imageUpdated = planImages[i];
        imageUpdated.media = m;
        promises.push(
          dispatch(
            savePlanImage(projectId, planId, imageUpdated._id, imageUpdated)
          )
        );
      }
    });

    Promise.all(promises)
      .then(() => {
        this.setState(
          ps => ({
            ...ps,
            image: null,
            loading: false
          }),
          () => this.props.onCancel()
        );
      })
      .catch(err => {
        this.setState(
          ps => ({
            ...ps,
            image: null,
            loading: false
          }),
          () => this.props.onCancel()
        );
      });
  }

  openNewTab() {
    let { image } = this.state;
    if (image && image.media) window.open(image.media.source, '_blank');
  }

  render() {
    let { image, images } = this.state;
    if (!image) return null;

    return (
      <GalleryModal
        images={images}
        selected={image.id}
        openNewTab={() => this.openNewTab()}
        closeModal={() => this.closeModal()}
        crudPoints={true}
      />
    );
  }
}

export default connect()(translate('translations-fr')(ImageDetails));
