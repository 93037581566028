import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import { Spinner } from '../../../../components';
import { addPlanImage } from '../../../../helpers/actions/projects';

class ImageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: {},
      planId: null,
      projectId: null,
      loading: true,
      files: [],
      images: []
    };
  }

  componentWillMount() {
    let { image, planId, projectId } = this.props;
    this.setState(ps => ({
      ...ps,
      image,
      planId,
      projectId,
      loading: false
    }));
  }

  onChange(name, nameValid, value, valid) {
    let { image } = this.state;

    this.setState({
      image: { ...image, [name]: value },
      [nameValid]: valid ? 'has-success' : 'has-danger'
    });
  }

  validate() {
    return this.state.files.length ? true : false;
  }

  savePlanImage() {
    if (this.validate()) {
      this.setState({ loading: true });
      let { projectId, planId, image, files } = this.state,
        { dispatch } = this.props;

      dispatch(addPlanImage(projectId, planId, image, files))
        .then(newImage => {
          this.props.onConfirm(newImage);
        })
        .catch(() => this.setState({ loading: false }));
    }
  }

  handleImageChange(files) {
    let images = [];
    let promises = [];

    for (let i = 0; i < files.length; i++) {
      promises.push(
        new Promise(resolve => {
          let reader = new FileReader();
          reader.onloadend = () => {
            images.push(reader.result);
            resolve();
          };
          reader.readAsDataURL(files[i]);
        })
      );
    }

    Promise.all(promises)
      .then(() => {
        this.setState(ps => {
          return { ...ps, images, files };
        });
      })
      .catch(() => {});
  }

  handleClick() {
    let input = document.createElement('input');
    input.type = 'file';
    input.multiple = false;
    input.onchange = e => {
      e.preventDefault();
      this.handleImageChange(e.target.files);
    };
    input.click();
  }

  cleanModal() {
    this.setState({
      image: {},
      plan: {},
      loading: false
    });
  }

  closeModal() {
    this.cleanModal();
    this.props.onCancel();
  }

  render() {
    let { image, loading, images } = this.state;

    return (
      <Modal
        size="md"
        isOpen={!!image}
        toggle={() => this.closeModal()}
        /* className={"resize-modals"} */
      >
        {loading ? <Spinner /> : null}
        <ModalHeader
          className="justify-content-left"
          toggle={() => this.closeModal()}
        >
          <Trans>Add Image</Trans>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={12}>
              <Row>
                <Col xs={12}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.titleValid
                    }
                  >
                    <Label>
                      <Trans>Title</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.title = node)}
                      namevalid="nameValid"
                      value={image.title || ''}
                      required="required"
                      onChange={event =>
                        this.onChange(
                          'title',
                          'titleValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col sm={12}>
                  <Button
                    size={'sm'}
                    color="info"
                    onClick={() => this.handleClick()}
                  >
                    <Trans>Select Image</Trans>
                  </Button>
                  <Button
                    size={'sm'}
                    color="info"
                    onClick={() => this.handleClick()}
                  >
                    <Trans>Project Medias</Trans>
                  </Button>
                </Col>
                {images.map((i, index) => {
                  return (
                    <Col key={'image-image-' + index} xs={12}>
                      <div
                        className={'image-container'}
                        style={{ backgroundImage: 'url(' + i + ')' }}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => this.closeModal()}>
            <Trans>Close</Trans>
          </Button>
          <Button color="info" onClick={() => this.savePlanImage()}>
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(ImageModal));
