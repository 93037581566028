import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { Spinner } from '../../../../components';
import {
  loadPlanNote,
  savePlanNote,
  addPlanNote
} from '../../../../helpers/actions/projects';
import ReactQuill from 'react-quill';
import { quillConfig } from '../../../../helpers/formatters';
import { noteTypes } from '../../../../helpers/nomenclators';
import Select from 'react-select';

class NoteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note: {},
      planId: null,
      projectId: null,
      loading: true
    };
  }

  componentWillMount() {
    let { note, planId, projectId, dispatch } = this.props;
    if (note._id) {
      dispatch(loadPlanNote(projectId, planId, note._id))
        .then(note => {
          this.setState(ps => ({
            ...ps,
            note,
            planId,
            projectId,
            loading: false
          }));
        })
        .catch(err => {});
    } else {
      this.setState(ps => ({ ...ps, note, planId, projectId, loading: false }));
    }
  }

  componentWillReceiveProps({ note, projectId, planId, dispatch }) {
    if (note._id) {
      dispatch(loadPlanNote(projectId, planId, note._id))
        .then(note => {
          this.setState(ps => ({
            ...ps,
            note,
            planId,
            projectId,
            loading: false
          }));
        })
        .catch(err => {});
    } else {
      this.setState(ps => ({ ...ps, loading: false }));
    }
  }

  onChange(name, nameValid, value, valid) {
    let { note } = this.state;

    this.setState({
      note: { ...note, [name]: value },
      [nameValid]: valid ? 'has-success' : 'has-danger'
    });
  }

  validate() {
    let title = this.title;

    this.setState({
      [title.attributes.getNamedItem('namevalid').value]: title.validity.valid
        ? 'has-success'
        : 'has-danger'
    });

    return title.validity.valid;
  }

  savePlanNote() {
    if (this.validate()) {
      this.setState({ loading: true });
      let { projectId, planId, note } = this.state,
        { dispatch } = this.props;

      if (note._id) {
        dispatch(savePlanNote(projectId, planId, note._id, note))
          .then(newNote => {
            this.setState(ps => ({ ...ps, loading: false }));
          })
          .catch(() => this.setState({ loading: false }));
      } else {
        dispatch(addPlanNote(projectId, planId, note))
          .then(newNote => {
            this.props.onConfirm(newNote);
          })
          .catch(() => this.setState({ loading: false }));
      }
    }
  }

  cleanModal() {
    this.setState({
      note: {},
      plan: {},
      loading: false
    });
  }

  closeModal() {
    this.cleanModal();
    this.props.onCancel();
  }

  render() {
    let { note, loading } = this.state,
      { t } = this.props;

    return (
      <Modal
        size="lg"
        isOpen={!!note}
        toggle={() => this.closeModal()}
        className={''}
      >
        {loading ? <Spinner /> : null}
        <ModalHeader
          className="justify-content-left"
          toggle={() => this.closeModal()}
        >
          {note._id ? t('Update Note') : t('Create Note')}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12}>
              <FormGroup
                className={
                  'has-label form-validation-40 ' + this.state.titleValid
                }
              >
                <Label>
                  <Trans>Title</Trans>
                </Label>
                <Input
                  type="text"
                  innerRef={node => (this.title = node)}
                  namevalid="nameValid"
                  value={note.title || ''}
                  required="required"
                  onChange={event =>
                    this.onChange(
                      'title',
                      'titleValid',
                      event.target.value,
                      event.target.validity.valid
                    )
                  }
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label>
                  <Trans>Type</Trans>
                </Label>
                <Select
                  className="primary"
                  innerRef={node => (this.type = node)}
                  namevalid="typeValid"
                  value={note.type}
                  options={noteTypes.map(p => ({ label: t(p), value: p }))}
                  onChange={event =>
                    this.onChange(
                      'type',
                      'typeValid',
                      event ? event.value : null,
                      !!event
                    )
                  }
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <FormGroup check>
                <Label className={'d-block'}>
                  <Trans>Status</Trans>
                </Label>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={note.completed}
                    onChange={event =>
                      this.setState({
                        note: {
                          ...note,
                          completed: event.target.checked
                        }
                      })
                    }
                  />
                  <span className="form-check-sign" />
                  <Trans>Completed</Trans>
                </Label>
              </FormGroup>
            </Col>
            <Col xs={12}>
              <FormGroup
                className={
                  'has-label form-validation-40 ' + this.state.textValid
                }
              >
                <Label>
                  <Trans>Description</Trans>
                </Label>
                <ReactQuill
                  innerRef={node => (this.text = node)}
                  namevalid="textValid"
                  value={note.text || ''}
                  modules={quillConfig}
                  className={'quill-text-editor'}
                  onChange={value =>
                    this.onChange('text', 'textValid', value, true)
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => this.closeModal()}>
            <Trans>Close</Trans>
          </Button>
          <Button color="info" onClick={() => this.savePlanNote()}>
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(NoteModal));
